$light-green : #7CAE8D;
$red : #e14339;
$green : #8bbe44;
$orange : #FC6D4F;
$light-grey: #eeecec;
$dark-grey:#262626;

$light-blue:#009ad7;
$pink:#e4197c;
$yellow:#f09022;
$purple:#633186;

$black : #000000;
$white : #ffffff;