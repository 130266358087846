.mobile-menu{
    background-color: $light-blue;
    color: white;
    position: fixed;
    width: 100%;
    height: 300vh;
    z-index: 99999;
    top: 0;
    text-align: center;
}

.menu-link-mobile{
    text-decoration: none;
    padding-left:4%;
    color: white;
    font-size: 1.4em;
    font-weight: 700;
    padding-top: 15px;
    padding-bottom: 15px;
    display: block;
    border-top: solid 1px white;
    &-border-bottom{
        border-bottom: solid 1px white;
    }
}
