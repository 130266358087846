body{
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}

header{
    min-height:90px;
}

.ham-icon{
    width:45px;
    float: right;
    padding-top: 30px;
    margin-right: 10px;
    cursor: pointer;
}
@media only screen and (max-width: 442px){
    .Dropdown-control{
        padding: 8px 22px 8px 0px!important;
    }
}


@media only screen and (max-width: 1023px) {
    .ordenador{
        display: none!important;
    }
    .ordenador2{
        display: none!important;
    }
    .mobile{
        display: block!important;
    }
    .index1-right{
        text-align: center;
    }
    .left-side{
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    .index3{
        text-align: center;
    }
    .menu-nowrap{
        flex-wrap: nowrap;
    }
    .quienesSomos1-img{
        width: 100%;
    }
    .quienesSomos1-p{
        width: 100%;
    }
    .quienesSomos2-box{
        display: block!important;
        padding: 10px;
    }
    .capacitaciones-especialistas-name{
        text-align: center;
        h1{
            float: none!important;
        }
    }
    .index2-box-white{
        border: none!important; 
        padding: 0!important;
   }
   .supervisiones2-form{
    input{
        text-align: center;
        width: 100%!important;
        padding: 10px;
        margin-bottom: 8px;
    }
    .button-container{
        text-align: center;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
    }
    
    .ReactModal__Content{
        width: 97%!important;
    }
    .materiales{
        &-title{margin-left: 15px;}
        &-description{margin-left: 15px;}
    }
    .w-50{
        width: 100%!important;
    }
    .w-25{
        width: 50%!important;
        img{
            min-height: 200px;
        }
    }
  }
  @media only screen and (min-width: 1024px) {
    .ordenador{
        display: block!important;
    }
    .ordenador2{
        display: initial;
    }
    .mobile{
        display: none!important;
    }
  }

.menu{
    padding-top: 36px;
    &-logo-img{
        height: 69px;
        margin-top: 20px;
    }
}

.swiper-container{
    margin-top: 25px;
    img{
        width:100%;
    }
}

.form-container{
    background-color: $light-grey;
    padding: 3%;
    -webkit-box-shadow: 0px 9px 10px -2px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 9px 10px -2px rgba(0,0,0,0.75);
    box-shadow: 0px 9px 10px -2px rgba(0,0,0,0.75);
    margin-bottom: 25px;
    .errors{
        color: red;
        font-family: Open Sans, sans-serif;
    }
}

.input-form{
    background-color: $light-grey;
    border-color: rgb(202, 200, 200);
    border-width: 0px 0px 1px;
    border-radius: 0px;
    width: 100%;
    font-family: Open Sans, sans-serif;
    font-size: 18px;
    line-height: 32px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.menu{
    &-container{
        border-bottom: 1px solid#ababab;
        background-color: $light-grey;
        padding: 3%;
    }
    &-img-container{
        margin-top: auto;
        margin-bottom: auto;
    }
    &-img{
        width: 100%;
        border-radius: 50%;
        max-width: 160px;
    }
    &-name{
        font-family: 'Montserrat', sans-serif;
        font-size: 1.4em;
        margin: 0;
    }
    &-description{
        font-family: Open Sans, sans-serif;
        font-size: 1.0em;
        text-transform: uppercase;
        color: $dark-grey;
    }
    &-price-container{
        margin-top: auto;
        margin-bottom: auto;
    }
    &-price{
        font-size: 1.5em;
        color: $red;
    }
}

footer{
    .zona-celeste{
        background-color: $light-blue;
        padding-bottom: 15px;
    }
    .zona-blanca{
        h4{
            font-weight: normal;
        }
    }
    .left-side{
        margin-top: 20px;
        color: white;
        h4{
            padding: 0;
            margin: 0;
        }
    }
    .icon-footer{
        width: 45px;
    }
}

.icon-footer:hover{
    opacity: 0.5;
    cursor: pointer;
}

.ReactModal__Content{
    width: 48%;
    padding: 3%!important;
    background-color: rgb(240, 240, 240)!important;
    .title{
        font-weight: 900;
        margin: 0;
        padding: 0;
        &-purple{
            color: $purple;
        }
        &-pink{
            color: $pink;
        }
    }
}

.galeria-de-fotos-container{
/*     margin: auto;
    text-align: center; */
    padding: 1.5%;
}

.galeria-de-fotos-img{
    width: 100%;
    max-height: 200px;
    display: block;
    margin: auto;
    object-fit: cover;
}

.galeria-de-fotos-img2{
    width: 100%;
    max-height: 400px;
    display: block;
    margin: auto;
}

.materiales{
    margin-bottom: 30px;
    &-title{
        color: $purple;
        font-weight: 900;
        text-transform: uppercase;
    }
    &-price{
        color: $dark-grey;
        font-weight: 900;
        h2{padding: 0;
        margin: 0;}
        &-description{
            float: left;
            color: $purple;
        }
    }
}